.lead-details {
  padding: 24px;
  background: #fff;
  margin-left: 88px;
  margin-top: 44px;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(225, 228, 235, 0.5);

    &_title {
      display: flex;
      align-items: center;
      gap: 16px;

      .back-button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid #e1e4eb;
        background: #fff;
        cursor: pointer;
        transition: all 0.2s ease;
        height: 40px;
        white-space: nowrap;
        width: fit-content;

        &:hover {
          background: #f8f9fa;
          border-color: #222;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 14px;
          font-weight: 500;
          color: #222;
        }
      }

      h1 {
        margin: 0;
        font-size: 32px;
        font-weight: 600;
        color: #222;
      }
    }

    &_subtitle {
      margin: 0;
      font-size: 14px;
      color: #717171;
    }

    &_actions {
      display: flex;
      gap: 16px;

      .action-button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        img {
          width: 20px;
          height: 20px;
        }

        &.whatsapp {
          background-color: #25D366;
          color: white;
          border: none;

          &:hover {
            background-color: darken(#25D366, 5%);
          }
        }

        &.email {
          background-color: #f8f9fa;
          color: #222;
          border: 1px solid #e1e4eb;

          &:hover {
            background-color: #e9ecef;
          }
        }
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &_section {
    h2 {
      margin: 0 0 16px;
      font-size: 18px;
      font-weight: 600;
      color: #222;
    }

    &_content {
      background: #f8f9fa;
      border-radius: 12px;
      padding: 24px;

      .info-item {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          width: 20px;
          height: 20px;
          opacity: 0.7;
        }

        span {
          font-size: 16px;
          color: #222;
        }
      }

      .info-item-location {
        cursor: pointer;

        &:hover {
          transform: scale(1.005);
          transition: all 0.2s ease;
        }
        
        span {
          color: #007bff;
        }
      }

      .property-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 24px;

        .property-item {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .label {
            font-size: 14px;
            color: #717171;
          }

          .value {
            font-size: 16px;
            color: #222;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .lead-details {
    margin-left: 0;
    margin-top: 88px;
    padding: 16px;

    &_header {
      flex-direction: column;
      gap: 16px;

      &_actions {
        width: 100%;
        justify-content: stretch;

        .action-button {
          flex: 1;
          justify-content: center;
        }
      }
    }

    &_section {
      &_content {
        padding: 16px;

        .property-grid {
          grid-template-columns: 1fr;
          gap: 16px;
        }
      }
    }
  }
}