@import "../navbar/navbar.scss";
@import "../core/core.scss";

// Variables
$primary-color: #4299e1;
$text-color: #4a5568;
$heading-color: #2d3748;
$border-color: #e2e8f0;
$background-light: #f8fafc;
$success-color: #4CAF50;
$success-hover: #45a049;

// Breakpoints
$breakpoint-lg: 768px;
$breakpoint-md: 640px;
$breakpoint-sm: 480px;

// Base Container
.web-valuator-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 32px;
  background: white;
  border-radius: 12px;
  // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

  h1 {
    color: $heading-color;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
  }

  // Button Styles
  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .web-valuator-button {
      flex: 1;
      text-align: center;
      justify-content: center;
    }
  }
}

// Description Section
.web-valuator-description {
  margin-bottom: 32px;
  padding: 24px;
  background: $background-light;
  border-radius: 8px;
  border: 1px solid $border-color;

  h2 {
    color: $heading-color;
    font-size: 20px;
    margin-bottom: 16px;
  }

  h3 {
    color: $heading-color;
    font-size: 16px;
    margin: 24px 0 12px;
  }

  p {
    color: $text-color;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 16px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      color: $text-color;
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 8px;
      padding-left: 24px;
      position: relative;

      &:before {
        content: "•";
        color: $primary-color;
        position: absolute;
        left: 0;
      }
    }
  }
}

// Form Styles
.form-group {
  margin-bottom: 24px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: $text-color;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    color: $text-color;
    margin-bottom: 8px;
  }

  input,
  select {
    width: 100%;
    padding: 12px;
    border: 1px solid $border-color;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
    background-color: $background-light;

    &:focus {
      outline: none;
      border-color: $primary-color;
      box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
    }
  }

  code {
    background-color: $background-light;
    padding: 4px;
    border-radius: 4px;
    font-weight: 500;
  }
}

// Color Input Grid
.color-inputs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 8px;
}

.color-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  input[type="color"] {
    width: 100%;
    height: 45px;
    padding: 4px;
    cursor: pointer;
  }
  
  .color-value {
    width: 100%;
    padding: 8px;
    background: $background-light;
    border: 1px solid $border-color;
    border-radius: 8px;
    font-size: 14px;
    color: $text-color;
  }
}

// Modal Styles
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 20px;
    color: $heading-color;
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: auto;
  color: $text-color;
  
  &:hover {
    color: $heading-color;
  }
}

.modal-body {
  position: relative;

  p, li {
    margin-bottom: 16px;
    font-size: 12px;
    color: $text-color;
  }

  .code-inline {
    font-size: 12px;
    color: $text-color;
    background-color: $background-light;
    padding: 4px;
    border-radius: 4px;
  }

  .modal-body-list {
    list-style-type: decimal;
    padding-left: 24px;
    margin-bottom: 16px;
  }
}

.code-block {
  background: $background-light;
  padding: 16px;
  border-radius: 8px;
  overflow-x: auto;
  margin: 0;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  color: $heading-color;
  border: 1px solid $border-color;
}

.copy-button {
  margin-top: 16px;
  background: $primary-color;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  width: auto;
  display: block;
  margin-left: auto;
  
  &:hover {
    background: darken($primary-color, 10%);
  }
}

// Navigation
.back-button {
  background: none;
  border: none;
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  width: fit-content;

  &.absolute {
    position: absolute;
    top: 36px;
    left: calc($navbarWidth + 24px);

    @media (max-width: $mobileWidth) {
      display: none;
    }
  }
}

// Layout
.header-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;

  h1 {
    margin: 0;
  }
}

.lead-magnet-container {
  margin-left: $navbarWidth;

  @media (max-width: $mobileWidth) {
    margin-left: 0;
  }
}

// Responsive Styles
@media (max-width: $breakpoint-lg) {
  .color-inputs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $breakpoint-md) {
  .web-valuator-container {
    margin: 20px;
    padding: 20px;
    margin-top: 88px;

    .modal-content {
      width: 95%;
      margin: 10px;
      padding: 16px;
    }
    
    .code-block {
      font-size: 12px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .color-inputs-grid {
    grid-template-columns: 1fr;
  }
}

.back-button.absolute {
  position: absolute;
  top: 36px;
  left: calc($navbarWidth + 24px);
  background: none;
  border: none;
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px;
  color: #666;

  @media (max-width: $mobileWidth) {
    display: none;
  }
}

