@import "../navbar/navbar.scss";

.property-marker {
  display: flex;
  padding: 5px 8px;
  cursor: pointer;
  overflow: auto;
  border-radius: 20px;
  border: 5px solid #fff;
  background: #efefef;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

  &.hovered {
    background-color: black;

    span {
      color: white;
    }
  }

  &.selected {
    background: #ee3943;

    span {
      color: white;
    }
  }

  span {
    color: black;
    font-weight: bold;
    margin-top: 2px;
  }

  &_nibble {
    position: absolute;
    top: 30px;
    left: calc(50% - 10px);
  }

  &_favourite {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
}

.heatmap-cluster {
  cursor: pointer;
  padding: 14px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  text-shadow: 0 0px 6px rgba(0, 0, 0, 0.8),
               0 0px 6px rgba(0, 0, 0, 0.8);
  background: radial-gradient(
    circle at center,
    rgba(238, 57, 67, 0.95) 0%,
    rgba(238, 57, 67, 0.85) 20%,
    rgba(238, 57, 67, 0.65) 40%,
    rgba(238, 57, 67, 0.35) 70%,
    rgba(238, 57, 67, 0) 100%
  );
  box-shadow: 0 0 70px rgba(238, 57, 67, 0.7);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
    background: radial-gradient(
      circle at center,
      rgba(238, 57, 67, 1) 0%,
      rgba(238, 57, 67, 0.9) 20%,
      rgba(238, 57, 67, 0.7) 40%,
      rgba(238, 57, 67, 0.4) 70%,
      rgba(238, 57, 67, 0) 100%
    );
    box-shadow: 0 0 80px rgba(238, 57, 67, 0.8);
  }

  &.small {
    width: 60px;
    height: 60px;
    font-size: 11px;
    background: radial-gradient(
      circle at center,
      rgba(233, 141, 145, 0.9) 0%,
      rgba(233, 141, 145, 0.8) 20%,
      rgba(233, 141, 145, 0.6) 40%,
      rgba(233, 141, 145, 0.3) 70%,
      rgba(233, 141, 145, 0) 100%
    );
    box-shadow: 0 0 60px rgba(233, 141, 145, 0.65);
  }

  &.medium {
    width: 80px;
    height: 80px;
    font-size: 13px;
    background: radial-gradient(
      circle at center,
      rgba(234, 114, 120, 0.95) 0%,
      rgba(234, 114, 120, 0.85) 20%,
      rgba(234, 114, 120, 0.65) 40%,
      rgba(234, 114, 120, 0.35) 70%,
      rgba(234, 114, 120, 0) 100%
    );
    box-shadow: 0 0 70px rgba(234, 114, 120, 0.7);
  }

  &.large {
    width: 100px;
    height: 100px;
    font-size: 15px;
    background: radial-gradient(
      circle at center,
      rgba(238, 57, 67, 1) 0%,
      rgba(238, 57, 67, 0.9) 20%,
      rgba(238, 57, 67, 0.7) 40%,
      rgba(238, 57, 67, 0.4) 70%,
      rgba(238, 57, 67, 0) 100%
    );
    box-shadow: 0 0 80px rgba(238, 57, 67, 0.75);
  }
}

.property-marker-favourite {
  .property-marker_nibble {
    top: 32px;
  }
}

.map-cluster {
  cursor: pointer;
  background: #efefef;
  padding: 12px;
  border-radius: 100%;
  color: black;
  font-weight: bold;
  font-size: 12px;
  border: 5px solid #fff;

  &.padded-cluster {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.lesser-padded-cluster {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:hover,
  &.hovered {
    background-color: black;
    color: white;
  }
}

.map-toolbar-container {
  position: fixed;
  bottom: 20px;
  left: 112px; // 88px is the width of the navbar + 24px margin
  display: flex;
  gap: 12px;
  justify-content: left;

  &.with-side-panel {
    justify-content: flex-start;
    left: calc(88px + 16px); // 88px is the width of the navbar + 24px padding
  }
}

.map-toolbar {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 44px;
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 8px;

  &_item {
    cursor: pointer;
    padding: 6px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 32px;
    border-radius: 4px;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: #222;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &.inactive {
      cursor: default;

      span {
        color: #bababa;
      }
    }

    &.selected {
      background-color: #007aff26;

      span {
        color: #007aff;
      }
    }
  }

  &_zoom-controls {
    display: flex;
    gap: 8px;
  }

  &_divider {
    background: #e1e4eb;
    width: 1px;
    align-self: stretch;
  }
}

.show-plots-map-btn {
  position: absolute;
  bottom: 20px;
  right: 383px;
  font-weight: 600;

  @media screen and (max-width: $mobileWidth) {
    right: unset;
    left: 20px;
  }
}

.info-tray {
  background-color: rgba(242, 244, 248);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  bottom: 20px;
  right: 390px; // width of side panel + 24px padding

  &.expanded {
    width: calc(100vw - 494px);
  }

  &.collapsed {
    width: 200px;
    height: 44px;
  }
}

@media screen and (max-width: 1110px) {
  .polygon-selection-tray {
    display: none;
  }
}

#hoverInfo {
  position: absolute;
  display: flex;
  z-index: 999;
  background: #222;
  pointer-events: none;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 44px;
  // transition: all 0.1s ease-out;

  span {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
}

//temporarily added for mobile view
@media (max-width: $mobileWidth) {
  #hoverInfo {
    display: none;
  }

  .map-toolbar-container {
    bottom: 30px;
    left: 0;
    width: 100vw;
    justify-content: center;

    &.districtMapActive {
      justify-content: end !important;
      padding-right: 24px;
    }
  }

  .map-toolbar {
    height: auto;
  }

  .map-toolbar_zoom-controls {
    display: none;
  }
}
