@import "../navbar/navbar.scss";
@import "../core/core.scss";

.lead-table-container {
  padding: 24px;
  background: #fff;
  margin-left: $navbarWidth;
  padding-left: 16px;
  margin-top: 44px;

  @media (max-width: $mobileWidth) {
    margin-left: 0;
    padding: 16px;
  }

  .lead-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: $mobileWidth) {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }

    h1 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      color: #333;
    }

    .lead-table-actions {
      display: flex;
      gap: 16px;

      @media (max-width: $mobileWidth) {
        .create-valuator-btn,
        .export-csv-btn {
          display: none;
        }
      }
    }
  }

  .lead-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;

    @media (max-width: $mobileWidth) {
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    th, td {
      padding: 12px 16px;
      text-align: left;
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f8f9fa;
      font-weight: 600;
      color: #495057;
    }

    tr:hover {
      background-color: #f8f9fa;
    }

    td {
      color: #333;
    }
  }
} 