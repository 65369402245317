.upload-property-search-bar {
  &_container {
    z-index: 1;
    display: flex;
    justify-content: center;
    max-width: calc(100% - 48px);
    width: 100%;
    height: 44px;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 100px;
    left: 24px;
    border-radius: 8px;
    border-color: #e1e4eb;
    border-width: 1px;
    &_search-icon-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      min-width: 44px;
      height: 44px;
      border-radius: 0px 8px 8px 0px;
      background-color: #ffffff;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background-color: #ee3943;
        border-width: 1px;
        border-color: #ab3c23;

        img {
          filter: invert(100%) brightness(200%);
        }
      }

      img {
        width: 20px;
      }
    }

    &_divider {
      background: #e1e4eb;
      width: 1px;
      align-self: stretch;
    }
  }

  &_input-content {
    width: 100%;
    height: 44px;
    display: flex;
    border-radius: 8px 0px 0px 8px;

    input {
      height: 44px;
      width: 100%;
      background-color: #f2f4f8;
      border-radius: 8px 0px 0px 8px;
      padding-left: 16px;

      ::placeholder {
        color: #717171;
      }
    }
  }
}

.upload-modal-dropdown {
  height: 48px;
  border-color: #bababa;
  border-width: 1px;
  border-radius: 8px;

  .dropdown {
    height: 42px;
    width: 100%;
  }

  .dropdown.active {
    border-radius: 8px;
  }

  .dropdown_label-container span {
    font-size: 16px;
    color: #717171;
  }
}

.listing-agent-dropdown {
  .dropdown-list {
    z-index: 9999;
    position: fixed;
    border-radius: 8px;
    width: 560px;
  }
}

.listing-agent-dropdown.settings-modal {
  .dropdown-list {
    top: 225px;
  }
}

.listing-agent-dropdown.upload-modal {
  .dropdown-list {
    top: 395px;
  }
}

.checklist {
  min-width: 389px;
  max-height: fit-content;
  gap: 24px;
  border-radius: 16px;
  margin-right: 100px;
  margin-left: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_header {
    padding: 24px 24px 0 24px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #222222;
    }

    p {
      font-size: 14px;
      color: #717171;
    }
  }

  &_progress {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 24px;
    gap: 8px;

    &_percentage {
      font-size: 16px;
      font-weight: 600;
    }

    &_bar {
      background-color: #eeeef0;
      border-radius: 100px;
      width: 100%;
      height: 8px;

      &_fill {
        background-color: #12b76a;
        border-radius: 100px;
        height: 100%;
      }
    }
  }

  &_accordion-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 24px;
  }

  &_accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: #eeeef0;
    border-width: 1px;
    border-radius: 8px;
    height: 48px;
    padding: 12px;
    gap: 12px;
    cursor: pointer;

    &.open {
      height: auto; // Expands to fit content when open
      min-height: 50px; // Maintains minimum height
    }

    &_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 12px;
      align-items: center;
    }

    &_steps {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-out;

      &.open {
        max-height: 500px;
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    }

    &_step {
      display: flex;
      width: 100%;
      gap: 12px;
      margin-left: 5px;

      img {
        visibility: hidden;
        height: 18px;
        margin-top: 2px;
      }

      p {
        color: #717171;
      }

      &.line-through {
        p {
          text-decoration: line-through;
          color: #717171;
        }

        img {
          visibility: visible;
        }
      }
    }

    &_image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border-style: solid;
      border-color: #e1e4eb;
      border-width: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
    }

    .checked {
      background-color: #12b76a;
      border-color: #12b76a;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-self: start;
      flex-grow: 1;

      p {
        color: #717171;
        font-size: 14px;
        margin-left: 8px;
      }

      h3 {
        font-weight: 500;
      }
    }

    &_arrow-icon {
      img {
        transition: transform 0.3s ease-out;
      }

      &.open img {
        transform: rotate(180deg);
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: auto;
    border-top-width: 1px;
    border-top-color: #eeeef0;
    justify-content: center;
    padding: 24px;

    h2 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 24px;
    }

    &_settings {
      display: flex;
      width: 100%;

      .titles {
        display: flex;
        flex-direction: column;
        gap: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #717171;
        padding: 3px 0;
        min-width: fit-content;
        margin-right: 15px;
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        min-width: fit-content;
        justify-content: space-between;

        div {
          cursor: pointer;
        }
      }
    }
  }
}

.upload-page {
  background-color: #f2f4f8;
  height: auto;
  padding-top: 150px;
  padding-bottom: 150px;

  &_header {
    position: absolute;
    overflow-x: hidden;
    top: 0;
    width: 100%;
    display: flex;
    padding: 24px 100px 24px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    background-color: white;
    border-bottom: solid 1px #e1e4eb;
    z-index: 2;

    @keyframes fixed-header-animation {
      0% {
        top: -126px;
      }

      100% {
        top: 0;
      }
    }

    &.fixed-header {
      position: fixed;
      top: -126px;
      animation: fixed-header-animation 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1720px;
    }

    &_title-subtitle {
      display: flex;
      gap: 12px;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #717171;
      }
    }

    &_title {
      display: flex;
      height: 24px;
      gap: 16px;
      margin-left: 120px;

      span {
        color: #222;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &_action-buttons {
        display: flex;
        align-items: center;
        gap: 24px;

        img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
    }

    &_subtitle {
      display: flex;
      align-items: center;
      gap: 16px;

      .profile-image {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        overflow: hidden;
        border-width: 1px;
        border-color: #d9d9d9;

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }

    &_viewer-share-content {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 44px;
      min-width: fit-content;
    }

    &.mobile {
      display: flex;
      width: 100%;
      height: 60px;
      padding: 9px 16px;

      .property-details_header_wrapper {
        height: 100%;
      }

      .property-details_header_title-subtitle {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        overflow: hidden;
      }

      .property-details_header_title {
        padding: 0;
        gap: 12px;

        span {
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .property-details_header_subheader {
        padding: 0;
        margin: 0;
      }

      .property-details_header_subheader_price {
        padding: 0;

        span {
          font-size: 16px;
        }
      }

      .property-details_header_viewer-share-content {
        min-width: 44px;
        margin-right: 12px;
      }

      .like-btn,
      .report-btn {
        min-width: 44px;
        border: none;
      }
    }
  }
}

.media-upload {
  &_container {
    min-height: 497px;
    height: fit-content;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
  }

  &_header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom-width: 1px;
    border-bottom-color: #e1e4eb80 p {
      font-size: 20px;
      font-weight: 600;
      color: #212121;
    }
  }

  &_add-media-container {
    min-height: 384px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    flex: 1;
    height: 100%;
  }

  &_footer {
    width: 100%;
    height: fit-content;

    &_tabs {
      display: flex;
      height: 56px;
    }

    &_tab {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      cursor: pointer;
    }

    &_cta-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      border-top: 1px solid #e1e4eb;
    }
  }
}

.media-empty-tile {
  width: 100%;
  border-width: 4px;
  border-style: dashed;
  border-radius: 16px;
  border-color: #bababa;
  display: flex;
  justify-content: center;
  background-color: #fafbfc;

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: center;
    max-width: 220px;
    height: auto;
    flex-direction: column;
    gap: 16px;

    p {
      font-size: 14px;
      font-weight: 400;
      color: #717171;
      text-align: center;
    }
  }
}

.description {
  &_container {
    min-height: 363px;
    height: fit-content;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    position: relative;
  }

  &_header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-bottom-width: 1px;
    border-bottom-color: #e1e4eb80 p {
      font-size: 20px;
      font-weight: 600;
      color: #212121;
    }
  }

  &_add-description-container {
    min-height: 267px;
    width: 100%;
    padding: 16px 24px 24px 24px;
  }

  &_footer {
    width: 100%;
    height: fit-content;

    &_cta-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      border-top: 1px solid #e1e4eb;
    }
  }
}

#property-details-input-number {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // Add these styles to remove focus outline
  &:focus {
    outline: none;
    border-color: #bababa; // Keep the same border color as unfocused
    box-shadow: none;
  }
}
