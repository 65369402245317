@import "../core/core.scss";

.agency-side-panel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: $sidePanelWidth;
  height: 100svh;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-left: 4px solid #fff;
  background: rgba(242, 244, 248, 0.9);
  backdrop-filter: blur(25px);
}

.agency-panel-header {
  padding: 16px;
  display: flex;
  justify-content: center;
  height: 140px;
  border-bottom: 1px solid #dadada;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.agency-count {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  flex: 1;
  align-self: center;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  border-radius: 8px;
  border: 1.5px solid #bababa;
  padding: 8px 12px;
  position: relative;
  height: 44px;
  padding: 16px 20px;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    color: #4c4c4d;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.agency-panel-content {
  height: calc(100svh - 140px);
  padding: 12px;
  padding-bottom: 0;
  padding-top: 0;

  .agency-list {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
    /* Ensure content still scrolls */
    overflow-y: auto;
  }
}

/* Agency Filter Panel styles */
.agency-filter-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
}

.agency-filter-panel-header {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.agency-filter-panel-header_agency-count {
  flex: 1;
  padding: 0 12px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.agency-filter-panel {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.agency-filter-panel_section-header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 8px;
}

.agency-filter-panel_section {
  margin-bottom: 16px;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 8px;
  transition: box-shadow 0.3s ease;
}

.divider.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

// Add these animation styles
.property-filter-panel-container.agency {
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);
}

.property-filter-panel-container.agency.slide-in {
  transform: translateX(0);
}

.property-filter-panel-container.agency.slide-out {
  transform: translateX(100%);
}
