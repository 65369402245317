@import "../navbar/navbar.scss";

$search-border-radius: 8px;
$search-dropdown-width: 240px;

.search-bar-wrapper {
  display: flex;
  left: 88px; // navbar width
  width: calc(100vw - 88px); // full width - navbar
  z-index: 1;
  position: absolute;
  top: 24px;
  justify-content: center;

  &.with-side-panel {
    max-width: calc(
      100vw - 505px
    ); // navbar + side panel + padding (88px + 364px + 24px + 24px)
    left: 104px; // 24px + 88px (navbar + padding)
  }

  @media screen and (max-width: 1036px) {
    justify-content: flex-start;
    left: calc(88px + 24px); // navbar + padding
  }

  &.left-pinned {
    display: block;
    left: 0;
    width: 100vw;
  }
}

.search-bar-wrapper.left-pinned .search-bar {
  left: calc(88px + 24px) !important;
  position: relative;

  @media screen and (max-width: 1600px) {
    max-width: calc(100vw - 88px - 44px - 580px - 24px);
  }
}

.search-bar {
  z-index: 1;
  display: flex;
  justify-content: center;
  max-width: 900px;
  width: calc(100vw - 88px - 44px);
  transition: all 0.3s ease-out;

  &.inactive {
    opacity: 0;
  }

  &_dropdown-toggler {
    min-width: 135px;
    background-color: white;
    border-radius: $search-border-radius;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    span {
      color: #222;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      position: relative;
      right: 8px;
    }

    img {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 8px;
    }
  }

  &_dropdown {
    position: absolute;
    top: 35px;
    left: 0px;
    list-style-type: none;
    background-color: white;
    border-radius: $search-border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 135px;

    li {
      cursor: pointer;
      padding: 16px;
      display: flex;
      justify-content: center;

      &:first-child {
        padding-top: 24px;
      }

      span {
        font-size: 14px;
        flex: 1;
        align-items: center;
        padding-left: 14px;
      }
    }
  }

  &_input-content {
    width: 100%;
    height: 44px;
    position: relative;
    display: flex;

    input {
      height: 44px;
      outline: none;
    }

    &_search-results {
      list-style-type: none;
      background-color: white;
      margin-top: 0;
      padding-top: 8px;
      position: absolute;
      left: 0px;
      top: 44px;
      border-bottom-left-radius: $search-border-radius;
      border-bottom-right-radius: $search-border-radius;
      overflow: auto;
      width: 100%;
      height: auto;
      max-height: 435px;

      &_result {
        cursor: pointer;
        padding: 8px 0;
        padding-left: 8px;
        display: flex;

        &.active {
          background-color: #f2f4f8;
        }

        img {
          margin-top: 2px;
          margin-left: 16px;
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 16px;
          margin-left: 10px;
          flex: 1;
        }

        &_chip {
          margin-right: 16px;
          margin-left: 10px;
          display: flex;
          padding: 4px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          border-radius: 100px;

          &._dis {
            background-color: rgba(228, 204, 255, 0.5);
            color: #8c71ab;
          }

          &._mun {
            background-color: rgba(175, 244, 198, 0.5);
            color: #609e75;
          }

          &._prov {
            background-color: rgba(188, 227, 254, 0.6);
            color: #638399;
          }

          &._auto {
            background-color: rgba(255, 232, 162, 0.5);
            color: #a69359;
          }
        }
      }
    }
  }

  &_container {
    width: 100%;
    background-color: white;
    border-radius: $search-border-radius;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    height: 44px;
    display: flex;
    align-items: center;

    &_search-input {
      display: flex;
      width: 100%;

      &.has-search-results {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &_close-content {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
      }
    }

    &_search-icon-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      min-width: 44px;
      height: 44px;
      border-radius: 0px 8px 8px 0px;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background-color: #ee3943;
        border-width: 1px;
        border-color: #ab3c23;

        img {
          filter: invert(100%) brightness(200%);
        }
      }

      img {
        width: 20px;
      }
    }

    input {
      width: 100%;
      padding-left: 16px;
      border-radius: $search-border-radius;
    }
  }

  &.standalone {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    flex: 1;

    .search-bar_container {
      width: 100%;
      height: 50px;
      border-radius: 16px;
      padding: 5px;

      &_search-input {
        border-radius: 12px;
        height: 100%;
        cursor: text;
        background-color: #f2f4f8;

        &_close {
          width: 16px;
          padding-right: 7px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-left: 16px;
          margin-top: 10px;
        }

        input {
          flex: 1;
          outline: none;
          border: 0;
          background-color: transparent;
          padding-left: 8px;
        }
      }
    }
  }

  &_toggle-content {
    display: flex;
    background-color: #e1e4eb;
    border-radius: 8px;
    margin-right: 12px;
    height: 44px;
    padding: 4px;
    width: fit-content;

    .vertical-divider {
      height: 16px;
      align-self: center;
      position: relative;
      z-index: 1;
    }

    &.cma-mode {
      position: absolute;
      top: 12px;
      left: calc($navbarWidth + 12px);
      z-index: 1;

      @media screen and (max-width: $mobileWidth) {
        top: 77px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: fit-content;
      }
    }
  }

  &_toggle-btn {
    cursor: pointer;
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    width: 75px;

    &.sale {
      border-radius: 8px 0px 0px 8px;
    }

    &.sold {
      border-radius: 0px 8px 8px 0px;
    }

    &.selected {
      border-color: #ee3943;
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid #bababa;
      opacity: 0.8;
      background: #fafbfc;
      box-shadow: 0px 4px 6px 0px rgba(34, 34, 34, 0.2);
      z-index: 2;
      position: relative;

      span {
        font-weight: 600;
        font-size: 14px;
        color: #222;
      }
    }

    span {
      color: #717171;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &_divider {
    background: #e1e4eb;
    width: 1px;
    align-self: stretch;
  }
}

.cma-toggle-valuation-mode .search-bar_toggle-btn {
  width: fit-content;
}

.cma-toggle-valuation-mode {
  @media screen and (max-width: $mobileWidth) {
    margin: 0 auto;
    left: 0;
    right: 0 !important;
  }
}

.save-search-modal {
  &_notify {
    display: flex;
    align-items: center;
    border: solid 1px rgba(225, 228, 235, 1);
    border-radius: 8px;
    padding: 12px;
    background-color: rgba(242, 244, 248, 1);
    margin: 16px;

    &_info {
      font-size: 16px;
      font-weight: 500;

      strong: {
        font-weight: 600;
      }
    }

    &_btn {
      display: flex;
      height: 44px;

      span {
        text-align: center;
        margin: 0 20px 0 10px;
        font-weight: 500;
        font-size: 16px;
        text-wrap: nowrap;
      }
    }
  }
}

//temporary fix for mobile
@media (max-width: $mobileWidth) {
  .search-bar-wrapper {
    top: 74px;
    left: 10px;
    width: calc(100vw - 20px);

    &.with-side-panel {
      display: none;
    }
  }

  .search-bar {
    flex-direction: column-reverse;
    width: 100%;
    gap: 5px;

    &_container {
      width: 100%;
    }
  }
}
