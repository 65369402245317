@import "../navbar/navbar.scss";

.collections-details {
  &_content {
    width: calc(100% - 88px);
  }
}

.tile {
  position: relative;

  .hamburger-menu {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .title-content {
    padding-bottom: 0;
  }

  .notify-btn {
    font-weight: 500;
    font-size: 16px;
    height: 44px;
    display: flex;
    gap: 8px;
    justify-content: space-around;
    max-width: fit-content;

    img {
      height: 20px;
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .collections-page {
    margin-top: 50px;
  }

  .collections-details {
    margin-top: 50px;
    justify-content: center;
    padding-left: 0;

    &_content {
      max-width: calc(100vw - 8rem);
    }
  }
}

.no-collections {
  min-height: 365px;
  align-items: center;
}
