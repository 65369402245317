@import "../navbar/navbar.scss";
@import "../core/core.scss";

.catastro-panel {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    width: $sidePanelWidth;
    height: 100svh;

    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border: solid white 2px;
    background: rgba(242, 244, 248, 0.9);
    backdrop-filter: blur(25px);

  &_content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &.panel-hidden {
    left: -400px;
  }

  &_header {
    padding: 24px 24px 16px;
    border-bottom: 1px solid #e1e4eb;

    h2 {
      font-size: 18px;
      font-weight: 600;
      color: #222;
      margin: 0;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: #717171;
      margin: 0;
      margin-bottom: 4px;
    }

    .helper-text {
      font-style: italic;
      font-size: 13px;
      color: #999;
    }
  }

  &_area-selection_plots_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    
  }

  &_count {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  &_area-selection {

    &_empty {
      margin-top: 24px;
      padding: 0 24px;
      color: #717171;
      font-size: 14px;
    }

    &_plots_clear {
      color: #717171;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      opacity: 0.75;
    }
  }

  /* Stage progress bar styles - similar to CMA */
  &_stage {
    margin-top: 16px;
    display: flex;
    padding: 0 32px;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      gap: 6px;

      span {
        text-align: center;
        color: #717171;
        font-size: 14px;
      }

      &_num {
        display: flex;
        align-self: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: white;
        border: solid 1px #e1e4eb;

        span {
          align-self: center;
          color: #717171;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }

      &.catastro-stage-active {
        span {
          color: #222;
          font-weight: 500;
        }

        .catastro-form_stage_item_num {
          background-color: #ee3943;
          border-color: black;

          span {
            color: white;
            font-weight: normal;
          }
        }
      }
    }

    .divider {
      margin-top: 14px;
      width: auto;
      flex: 1;
    }
  }

  /* Municipality stage styles */
  &_municipality-stage {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &_search-container {
    padding: 16px 24px;
    border-bottom: 1px solid #e1e4eb;
  }

  &_search-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #e1e4eb;
    border-radius: 8px;
    font-size: 14px;
    outline: none;

    &:focus {
      border-color: #2a80eb;
    }
  }

  &_municipalities-list {
    flex: 1;
    overflow-y: auto;
    padding: 16px 24px;
  }

  &_municipality-item {
    padding: 16px;
    border: 1px solid #e1e4eb;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;

    &:hover {
      background-color: #f5f7fa;
      border-color: #2a80eb;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: #222;
    }

    .catastro-panel_municipality-province {
      font-size: 14px;
      font-weight: normal;
      color: #717171;
      margin-top: 4px;
    }
  }

  /* District stage styles */
  &_district-stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 24px;
  }

  &_district-helper {
    font-size: 16px;
    text-align: center;
    color: #222;
    margin-bottom: 24px;
    line-height: 1.5;
  }

  &_hide-panel {
    margin-top: 24px;
  }

  /* Filter stage styles */
  &_filter-stage {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &_filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e1e4eb;
  }

  &_filters {
    display: flex;
    flex-direction: column;
    width: 370px;
    padding: 20px;
    overflow-y: auto;
    z-index: 0;
    flex-grow: 1;
  }

  &_filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
  }

  &_filter-label {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-top: 24px;
  }

  &_filter-dropdown {
    width: 100%;
    display: block;
    margin-top: 12px;

    .dropdown {
        width: 100%;
    }
  }

  &_filter-range {
    display: flex;
    gap: 12px;
    width: 100%;

    .primary-input {
        margin-top: 12px;
    }
  }

  &_filter-input {
    height: 45px;
  }

  &_filter-range-separator {
    color: #717171;
    font-size: 14px;
  }

  &_results {
    flex: 1;
    overflow: hidden;
  }

  /* Footer styles */
  &_footer {
    border-top: 1px solid #e1e4eb;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &_loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 40px 24px;
    text-align: center;

    p {
      color: #222;
      font-size: 16px;
    }
  }

  &_no-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 24px;
    text-align: center;

    p {
      color: #717171;
      font-size: 16px;
    }
  }
}

/* Show panel button when panel is hidden */
.catastro-show-panel {
  position: fixed;
  top: 70px;
  left: 16px;
  z-index: 1000;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .catastro-panel {
    width: 100%;
    
    &_stage {
      padding: 12px;
      
      .divider {
        width: 20px;
      }
    }
  }
}

/* Catastro Card Styles */
.catastro-card {
  margin: 10px 0;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.2s, transform 0.2s;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &.selected {
    border-color: #2a80eb;
    background-color: #f0f7ff;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &_reference-number {
    font-weight: 600;
    font-size: 16px;
    color: #222;
  }

  &_status {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #717171;

    &-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 5px;

      &.registered {
        background-color: #4caf50;
      }

      &.pending {
        background-color: #ff9800;
      }
    }
  }

  &_body {
    margin-bottom: 10px;
  }

  &_location {
    font-size: 14px;
    color: #222;
    margin-bottom: 10px;
  }

  &_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  &_detail {
    font-size: 13px;
    display: flex;
    flex-direction: column;

    &-label {
      color: #717171;
      margin-bottom: 2px;
    }

    &-value {
      color: #222;
      font-weight: 500;
    }
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #717171;
    border-top: 1px solid #e1e4eb;
    padding-top: 10px;

    &-label {
      margin-right: 4px;
    }

    &-value {
      color: #222;
    }
  }
}

/* Show Map Button */
.catastro-show-map {
  display: none;
  margin-top: 24px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .catastro-panel {
    width: 100%;
    left: 0;
    right: unset;
  }
  
  .catastro-show-map {
    display: block;
  }
}

/* Catastro Filter Panel Styles */
.catastro-filter-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(242, 244, 248, 0.9);
  backdrop-filter: blur(25px);

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #e1e4eb;
  }

  &_title {
    color: #222;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
  }

  &_close-button {
    cursor: pointer;
    color: #2a80eb;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  &_content {
    flex: 1;
    overflow-y: auto;
    padding: 24px;
  }

  &_section {
    margin-bottom: 24px;
  }

  &_section-title {
    color: #222;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &_options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &_option {
    padding: 8px 16px;
    background-color: white;
    border: 1px solid #e1e4eb;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #2a80eb;
      color: white;
      border-color: #2a80eb;
    }
  }

  &_range {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      flex: 1;
      padding: 8px 12px;
      border: 1px solid #e1e4eb;
      border-radius: 4px;
      font-size: 14px;
      transition: border-color 0.2s;

      &:focus {
        border-color: #2a80eb;
        outline: none;
      }
    }

    span {
      color: #717171;
    }
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 30px;
  }

  &_clear-button,
  &_apply-button {
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
    flex: 1;
  }

  &_clear-button {
    background-color: white;
    color: #333;
    border: 1px solid #e1e4eb;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &_apply-button {
    background-color: #2a80eb;
    color: white;

    &:hover {
      background-color: #1a70db;
    }
  }
}

/* Catastro Search Bar Styles */
.catastro-search-bar {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 360px;
  z-index: 11;

  &_container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &.active {
      border-radius: 8px 8px 0 0;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    .catastro-search-bar_toggle-icon {
      margin-left: auto;
      width: 14px;
      height: 14px;
    }
  }

  &_label {
    flex: 1;
    font-size: 14px;
    color: #555;
  }

  &_dropdown {
    border-top: 1px solid #eee;
    padding: 10px 0;
  }

  &_search-types {
    display: flex;
    padding: 0 16px 10px;
    border-bottom: 1px solid #eee;
  }

  &_search-type {
    margin-right: 16px;
    font-size: 14px;
    color: #555;
    cursor: pointer;
    padding: 5px 0;
    position: relative;

    &.active {
      color: #2a80eb;
      font-weight: 500;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #2a80eb;
      }
    }
  }

  &_search-input-container {
    padding: 10px 16px;
  }

  &_search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    outline: none;

    &:focus {
      border-color: #2a80eb;
    }
  }

  &_search-results {
    max-height: 300px;
    overflow-y: auto;
    border-top: 1px solid #eee;
  }

  &_search-result {
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &_search-loading,
  &_no-results {
    padding: 15px;
    text-align: center;
    color: #666;
    font-size: 14px;
  }
}

#catastro-panel {
  &.panel-hidden {
    transform: translateX(100%);
  }
}

.catastro-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &_header {
    padding: 24px;
  }

  h2 {
    color: #222;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    margin-top: 12px;
    color: #717171;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    // font-weight: 500;
  }

  &_stage {
    margin-top: 16px;
    display: flex;
    padding: 0 32px;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      gap: 6px;

      span {
        text-align: center;
        color: #717171;
        font-size: 14px;
      }

      &_num {
        display: flex;
        align-self: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: white;
        border: solid 1px #e1e4eb;

        span {
          align-self: center;
          color: #717171;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }

      &.catastro-stage-active {
        span {
          color: #222;
          font-weight: 500;
        }

        .catastro-form_stage_item_num {
          background-color: #ee3943;
          border-color: black;

          span {
            color: white;
            font-weight: normal;
          }
        }
      }
    }

    .divider {
      margin-top: 14px;
      width: auto;
      flex: 1;
    }
  }

  .divider {
    width: 95px;
    height: 1px;
    background: #e1e4eb;
    margin-top: 18px;
  }

  &_search {
    margin-top: 24px;
    padding: 0 24px;

    .search-bar_input-content {
      position: relative;
      width: 100%;
    }

    .search-bar_input-container {
      border-radius: 16px;
      background-color: white;
      padding: 4px;
      flex: 1 1;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .search-bar_input-button-container {
      background-color: white;
      border: none;
      padding: 12px;
      margin: 0;
      height: 40px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      right: 28px;
    }

    .search-bar_input {
      width: 100%;
      font-size: 14px;
      outline: none;
      border-radius: 12px;
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: #f2f4f8;
      height: 40px;

      &:focus {
        border-color: #2a80eb;
      }

      &::placeholder {
        color: #8b8b8b;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
      }
    }

    .search-bar_input-content_search-results {
      position: absolute;
      top: 100%;
      background: white;
      border: 1px solid #e1e4eb;
      border-radius: 8px;
      margin-top: 4px;
      max-height: 300px;
      overflow-y: auto;
      z-index: 1000;
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
      width: calc(100% - 48px);
      left: 24px;

      &_result {
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover, &.active {
          background-color: #f5f5f5;
        }

        &_chip {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 500;

          &._mun {
            background-color: #e3f2fd;
            color: #1976d2;
          }

          &._dis {
            background-color: #f3e5f5;
            color: #7b1fa2;
          }

          &._loc {
            background-color: #e8f5e9;
            color: #388e3c;
          }

          &._zone {
            background-color: #fff3e0;
            color: #f57c00;
          }
        }
      }
    }
  }

  &_search-results {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;

    &_empty {
      margin-top: 24px;
      padding: 0 24px;
      color: #717171;
      font-size: 14px;
    }
  }

  &_details {
    margin-top: 24px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    &_header {
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #222;
        margin: 0;
        margin-bottom: 8px;
      }

      p {
        margin-top: 0;
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        color: #717171;
        font-size: 14px;
      }

      .value {
        color: #222;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &_empty {
      margin-top: 24px;
      padding: 0 24px;
      color: #717171;
      font-size: 14px;
    }
  }

  &_area-selection {
    margin-top: 24px;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;

    &_empty {
      margin-top: 24px;
      padding: 0 24px;
      color: #717171;
      font-size: 14px;
    }
  }

  &_footer {
    position: fixed;
    bottom: 0;
    width: $sidePanelWidth;
    padding: 16px 24px;
    background: white;
    border-top: 1px solid #e1e4eb;
    display: flex;
    justify-content: space-between;
  }
}

// Catastro card styling
.catastro-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e1e4eb;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #3e82e5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }

  &.selected {
    border-color: #3e82e5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &_reference-number {
    font-weight: 600;
    font-size: 14px;
    color: #222;
  }

  &_status {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    color: #717171;

    &-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &.registered {
        background-color: #4caf50;
      }

      &.pending {
        background-color: #ffc107;
      }
    }
  }

  &_location {
    font-size: 14px;
    color: #222;
    margin-bottom: 12px;
  }

  &_body {
    display: flex;
    flex-direction: column;
  }

  &_details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &_detail {
    display: flex;
    align-items: center;
    gap: 8px;

    &-label {
      color: #717171;
      font-size: 12px;
      min-width: 80px;
    }

    &-value {
      color: #222;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

// Mobile responsive styles
@media (max-width: 768px) {
  .catastro-panel {
    width: 100%;
  }

  .catastro-show-map {
    display: block;
  }

  .catastro-form_footer {
    width: 100%;
  }
} 