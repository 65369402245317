@import "../navbar/navbar.scss";
@import "../core/core.scss";

.cma-panel {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  width: $sidePanelWidth;
  height: 100svh;

  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border: solid white 2px;
  background: rgba(242, 244, 248, 0.9);
  backdrop-filter: blur(25px);
}

#cma-panel {
  &.panel-hidden {
    transform: translateX(100%);
  }
}

.cma-show-map {
  display: none;
  margin-top: 24px;
  margin-left: 8px;
}

.cma-show-panel {
  position: fixed;
  top: 78px;
  left: 18px;
}

.cma-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &_header {
    padding: 24px;
  }

  h2 {
    color: #222;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    margin-top: 12px;
    color: #717171;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    // font-weight: 500;
  }

  &_stage {
    margin-top: 16px;
    display: flex;
    padding: 0 32px;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      gap: 6px;

      span {
        text-align: center;
        color: #717171;
        font-size: 14px;
      }

      &_num {
        display: flex;
        align-self: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: white;
        border: solid 1px #e1e4eb;

        span {
          align-self: center;
          color: #717171;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }

      &.cma-stage-active {
        span {
          color: #222;
          font-weight: 500;
        }

        .cma-form_stage_item_num {
          background-color: #ee3943;
          border-color: black;

          span {
            color: white;
            font-weight: normal;
          }
        }
      }
    }

    .divider {
      margin-top: 14px;
      width: auto;
      flex: 1;
    }
  }

  &_search {
    margin-top: 40px;
    display: flex;
    padding: 0 24px;
  }

  &_help-container {
    padding: 0 24px;
  }

  &_help-dialog {
    margin-top: 24px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1.5px solid #d2d4dd;
    background: rgba(225, 228, 235, 1);

    p {
      margin: 0;
    }

    p,
    span {
      color: #717171;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      li {
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        span {
          font-size: 12px;
        }
      }
    }

    &_num {
      border-radius: 50%;
      border: solid 1px rgba(113, 113, 113, 0.3);
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;

      span {
        align-self: center;
        font-size: 12px;
        color: #717171;
      }
    }

    &_desc {
      flex: 1;
    }
  }

  &_search-results {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
    flex: 1;
    overflow-y: auto;
    padding: 0 24px;
    padding-bottom: 24px;
  }

  &_selected-flow {
    margin-top: 24px;
    width: 100%;
    padding: 24px;

    &_categories,
    &_features {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
    }

    &_row {
      display: flex;
      gap: 24px;
      width: 100%;

      &.feature_set {
        margin-top: 12px;
        padding-bottom: 20px;

        .checkbox label {
          font-size: 11px;
        }
      }
    }

    &_col {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 8px;
      width: 50%;

      &.disabled {
        opacity: 0.4;
      }

      &_checkbox {
        cursor: pointer;
      }

      p,
      h2 {
        margin-left: 4px;
        color: #222 !important;
        font-family: Inter;
        font-size: 14px;
        margin-top: 0;
      }

      input {
        padding: 14px !important;
      }
    }
  }

  &_footer {
    position: fixed;
    height: 60px;
    width: 100%;
    background: white;
    border-top: #e1e4eb;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    bottom: 0;
    right: 0;
    z-index: 999;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .cma-footer-btn {
      padding: 0 16px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
    }
  }

  &_stage-2 {
    margin-top: 32px;
    flex: 1;
    overflow: auto;
    border-top: solid 1px #e1e4eb;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .district-selection-panel-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: stretch;
      flex: 1;
      overflow-y: auto;
      padding-bottom: 60px;
    }

    .cma-search-input {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;

      input {
        width: 288px;
        border: 1px solid #cbcbcb;
        border-radius: 8px;
        display: block;
        padding: 9px 4px 9px 40px;
        background: transparent url("../../assets/search/search_icon.svg")
          no-repeat 13px;
      }
    }
  }
}

.cma-property-card {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  background: white;
  border: 1px solid transparent;

  h2 {
    color: #222;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    margin: 0;
  }

  &_footer {
    display: flex;
    align-items: center;
    align-self: stretch;

    span {
      color: #717171;
      font-size: 14px;
    }

    .vertical-divider {
      margin: 0 8px;
    }

    &_constructed {
      flex: 1;
    }

    &_link {
      display: flex;
      align-self: flex-end;
      justify-self: flex-end;

      span {
        color: #222;
        font-weight: 500;
      }

      img {
        width: 16px;
        height: 16px;
        align-self: center;
        margin-left: 4px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #222;
  }

  &.selected {
    background-color: transparent;
    border: 1px solid #ced0d9;
    cursor: default;
  }
}

.cma-property-panel {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
  background: #f2f4f8;
  flex: 1;

  &.expanded {
    position: absolute;
    width: calc(100vw - 88px);
    right: 0;
    top: -2px;
    margin-top: 0;
    height: 100svh;

    .cma-property-panel_content {
      display: flex;
      flex: 1;
      overflow-y: auto;
    }

    .divider {
      position: absolute;
      top: 75px;
      left: 0;
    }
  }

  &_header {
    padding: 0 24px;
    padding-top: 16px;

    &.fixed_header {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 999;
      background-color: #f2f4f8;
      padding-bottom: 16px;

      .divider {
        position: relative;
        bottom: -16px;
        left: -24px;
        width: calc(100% + 48px);
      }
    }

    &_select-all {
      flex: 1;
      display: flex;
      justify-content: end;
      gap: 8px;
      cursor: pointer;

      span {
        align-self: center;
        font-size: 14px;
      }
    }
  }

  &_content {
    flex: 1;
    display: flex;
    overflow-y: auto;
  }

  &_properties {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
    padding-bottom: 80px;
    width: 100%;
  }

  &_property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 24px;
    padding: 20px;
    padding-bottom: 88px;
  }
}

.cma-model_content {
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &_header {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #717171;
  }

  &_valuation {
    display: flex;
    border-radius: 8px;
    border: 1px solid #e1e4eb;
    background: #f2f4f8;
    padding: 16px;
    padding-bottom: 14px;
    gap: 16px;

    &_col {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    span {
      color: #717171;
      font-size: 14px;
    }

    .valuation_value {
      font-size: 24px;
      font-weight: 600;
      color: #222;
    }

    .estimated_range_wrapper {
      margin-top: 10px;
      display: flex;
      gap: 4px;

      .estimated_range {
        font-size: 16px;
        font-weight: 500;
        color: #222;
      }
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_row {
      width: 100%;
      flex: 1;
      display: flex;
      gap: 16px;

      &_col {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.valuation-slider {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  h2 {
    font-weight: normal;
    color: #666;
  }

  &_monthly-label {
    font-size: 14px;
    color: #717171;
  }

  .info-icon {
    font-size: 0.8em;
    color: #999;
    padding: 0 4px;
    cursor: pointer;
  }

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    color: #222;
  }

  .slider-container {
    position: relative;
    width: calc(100% - 76px);
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin-top: 24px;
    border-radius: 100px;
    background: #e1e4eb;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #ee3943;
  }

  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: white;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #ee3943;
  }

  .slider-labels {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #222;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    justify-content: center;

    .slider-label {
      &:first-child {
        position: absolute;
        left: 0;
        transform: translateX(-50%);
      }

      &:last-child {
        position: absolute;
        right: 0;
        transform: translateX(50%);
      }
    }
  }

  .recommended-label {
    font-size: 0.8em;
    color: #666;
    text-align: center;
  }
}

.valuation-slider-tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 232px;
  border-radius: 8px;
  background-color: #222 !important;
  padding: 16px;

  h2 {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: $mobileWidth) {
  .cma-panel {
    top: 50px;
    width: 100%;
    height: calc(100svh - 50px);
  }

  .cma-form {
    margin-bottom: 60px;
  }

  .cma-search-input {
    padding: 0 24px;

    input {
      width: 100% !important;
    }
  }

  .property-panel_expand {
    display: none !important;
  }

  .cma-property-panel_header.fixed_header {
    display: none;
  }

  .cma-property-panel_properties {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 24px;
    padding: 20px;
  }

  .cma-show-map {
    display: flex;
  }

  .cma-form_header {
    padding-top: 16px;
    padding-bottom: 0;
  }

  .or-draw-txt {
    display: none;
  }

  .cma-form_stage {
    display: none;
  }

  .map-toolbar-container.with-side-panel {
    left: 0 !important;
    justify-content: center !important;
  }
}

.yes-no-option {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;

  &_question {
    font-size: 14px;
    color: #222;
  }

  &_switch {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    &_option {
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      height: 44px;
      padding: 12px;
      border-radius: 8px;
      background-color: white;

      &.selected {
        background-color: #ee3943;

        span {
          color: white;
        }
      }

      span {
        flex: 1;
        text-align: center;
        font-size: 14px;
      }
    }
  }
}

.valuation-details {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  gap: 16px;

  &_title {
    color: #222;
    font-size: 18px;
    font-weight: 600;
  }

  &_title_info {
    color: #717171;
    font-size: 14px;
  }

  &_options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    align-self: stretch;
    overflow: auto;
  }

  &_dropdown {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;

    span {
      font-size: 14px;
    }
  }
}
