.generic-search-input {
  flex: 1;

  &_container {
    border-radius: 16px;
    background-color: white;
    padding: 4px;
    flex: 1;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);

    &_search-input {
      border-radius: 12px;
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: #f2f4f8;

      img {
        width: 20px;
        height: 20px;
      }

      input {
        flex: 1;
        background-color: transparent;
        outline: none;
        color: #222;

        &::placeholder {
          color: #8b8b8b;

          /* T/M/Light */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 150%; /* 24px */
        }
      }
    }

    &_search-button {
      background-color: white;
      border: none;
      padding: 12px;
      margin: 0;
      height: 40px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      right: 28px;
    }
  }
}
