@import "../navbar/navbar.scss";

.settings_tabs {
  display: flex;
  gap: 24px;

  &_nav {
    width: 288px;
    display: flex;
    flex-direction: column;
    width: 288px;
    align-items: flex-start;
    background-color: white;
    border-radius: 16px;

    &_item {
      cursor: pointer;
      padding: 24px;
      height: 72px;
      display: flex;
      gap: 12px;
      align-self: stretch;

      img {
        align-self: center;
        width: 24px;
        height: 24px;
      }

      &.active {
        span {
          color: #ee3943;
          font-weight: 600;
        }
      }
    }
  }

  &_content {
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    min-width: 600px;
    height: fit-content;

    &_header {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      position: relative;

      h2 {
        color: #222;
        font-size: 20px;
        font-weight: 600;
      }

      span {
        color: #717171;
        font-size: 16px;
      }

      &_col {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &:first-child {
          flex: 1;
        }
      }

      &_row {
        display: flex;
        gap: 16px;
      }

      .divider {
        margin-top: 16px;
        position: relative;
        left: -16px;
        width: calc(100% + 32px);
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 8px;

      &_logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }

      &_row {
        display: flex;
        position: relative;
      }

      &_company {
        display: flex;
        margin-left: 8px;
        flex-direction: column;
        justify-content: center;

        span {
          &:last-child {
            color: #717171;
            font-size: 14px;
          }
        }
      }

      .upload_new_logo {
        display: flex;
        flex-direction: column;
        justify-self: flex-end;
        position: absolute;
        right: 16px;
        top: 38px;
      }

      &_col {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-right: 16px;

        .setting-heading {
          color: #717171;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .primary-input {
        border-radius: 8px;
        border: 1px solid #bababa;
        padding: 12px;
      }

      .settings-save {
        align-self: flex-end;
        margin-top: 8px;
        margin-right: 16px;
      }
    }
  }

  .subscription-status {
    margin-top: 8px;

    .cancel-subscription {
      margin-top: 8px;
      padding-left: 0;
    }

    .resume-subscription {
      padding: 8px;
      width: 180px;
    }

    &_modify {
      display: flex;
      flex-direction: column;
    }

    &_active {
      background-color: #52b963;
      color: white;
      padding: 8px;
      border-radius: 16px;
    }

    &_plan {
      margin-left: 6px;
      background-color: transparent;
      color: #222;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid #222;
    }
  }

  .settings-cancel {
    margin-right: 14px;
  }
}

.settings-page {
  position: relative;

  .log-out-btn {
    position: absolute;
    bottom: 24px;
    right: 24px;
    border: solid 2px #b0b0b0;
    border-radius: 0.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.change-password-modal {
  &_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .primary-input {
      border-radius: 8px;
      border: 1px solid #bababa;
      padding: 12px;
    }
  }

  .change-password-btn {
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.settings_tabs_content_body {
  &.team {
    .settings_tabs_content_body_row {
      .settings_tabs_content_body_col {
        flex: 1;

        &:first-child {
          flex: 3;
        }
      }

      .invitation-pending {
        border-radius: 50px;
        color: #222;
        background: yellow;
        text-align: center;
        font-size: 12px;
        width: fit-content;
        padding: 4px 8px;
      }

      .invitation-accepted {
        border-radius: 50px;
        color: white;
        background: green;
        text-align: center;
        font-size: 12px;
        width: fit-content;
        padding: 4px 8px;
      }
    }
  }

  .remove-team-user-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-top: 4px;

    &.not-allowed {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.settings_tabs_content_header_col {
  &.team {
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.invite-member-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      font-size: 14px;
    }

    .primary-input {
      border-radius: 8px;
      border: 1px solid #bababa;
      padding: 12px;
    }
  }
}

// up to medium tailwindcss
@media screen and (max-width: $mobileWidth) {
  .settings-page {
    padding-left: 16px !important;
  }

  .log-out-btn {
    position: unset !important;
    margin-bottom: 16px;
    border: solid 2px #b0b0b0;
    border-radius: 0.5rem;
    border-radius: 0.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .settings_tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    margin-bottom: 24px;

    &_nav {
      width: 100%;
      margin-bottom: 0px;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      flex-direction: row;
      background-color: white;
      align-items: center;

      &_wrapper {
        display: flex;
        overflow-x: auto;
        width: 100%;
        // border-bottom: 1px solid rgba(225, 228, 235, 0.2);
        background-color: transparent;
      }

      .divider {
        // turn to vertical divider
        width: 2px;
        height: 50%;
        background-color: #f2f4f8 !important;
      }

      &_item {
        padding: 16px;
        height: 56px;
        width: 20%;
        justify-content: center;
        background-color: white;
        border-radius: 8px 8px 0px 0px;

        span {
          display: none;
        }

        img {
          display: block !important;
          width: 24px;
          height: 24px;
        }
      }
    }

    &_content {
      min-width: 100%;
      border-radius: 0px 0px 8px 8px;

      &_header {
        &_row {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }

        &_col {
          gap: 0px;

          &.team {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            span {
              width: 100%;
              font-size: 0.9rem;
            }

            div {
              width: 100%;
              margin-top: 4px;
              width: fit-content;
            }
          }

          h2 {
            font-size: 1rem;
          }

          div {
            width: fit-content;
          }
        }
      }

      &_body {
        &_row {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &_logo {
          width: 65px;
          height: 65px;

          img {
            width: 65px;
            height: 65px;
          }
        }

        &_company {
          margin-left: 0;
        }

        &_col {
          width: 100%;
          gap: 8px;

          .setting-heading {
            font-size: 0.9rem;
          }
        }

        .upload_new_logo {
          position: relative;
          top: 0;
          right: 0;
          margin-top: 0;
          margin-right: 0;
        }

        .settings-save {
          margin-top: 0;
          margin-right: 0;
        }
      }

      &_body {
        &.team {
          .settings_tabs_content_body_row {
            flex-direction: unset;
            gap: 16px;
          }
        }
      }
    }
  }
}

.custom-conditions-modal {
  &_content {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
  }
}
